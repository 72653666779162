interface Category {
  key: string
  name: string
  apps: App[]
}

interface App {
  name: string
  link?: string
  variant?: string
  message?: string
}

const appCategories: Category[] = [
  {
    key: 'campaign',
    name: 'Campaign Apps',
    apps: [
      {
        name: 'VRM',
        link: 'https://vrm.voteda.org'
      },
      {
        name: 'Self Canvass',
        link: 'https://check.da.org.za'
      },
      {
        name: 'Activity Track',
        link: 'https://track.voteda.org'
      }
    ]
  },
  // {
  //   key: 'eday',
  //   name: 'Election Day Apps',
  //   apps: [
  //     {
  //       name: 'Counting',
  //       message: 'Turnout Counting',
  //       link: 'https://counting.voteda.org'
  //     },
  //     {
  //       name: 'Results',
  //       message: 'Final Results',
  //       link: 'https://results.voteda.org'
  //     },
  //     {
  //       name: 'E-Day Reports',
  //       link: 'https://eday-reports.voteda.org'
  //     },
  //     {
  //       name: 'PVT',
  //       message: 'Parallel Vote Tabulation',
  //       link: 'https://eday-pvt.voteda.org/'
  //     }
  //   ]
  // },
  {
    key: 'finance',
    name: 'Finance Apps',
    apps: [
      {
        name: 'Electronic Payment Requisition System (EPRS)',
        link: 'https://payments.voteda.org'
      },
      {
        name: 'Donor Vetting & Registration',
        link: 'https://vetting.voteda.org'
      },
      {
        name: 'Debit Order E-mandate App',
        link: 'https://dashboard.emandate.co.za/login'
      },
      {
        name: 'Fieldwork Documentation App',
        link: 'https://fielddoc.voteda.org'
      }
    ]
  },
  {
    key: 'mapping',
    name: 'Mapping Apps',
    apps: [
      {
        name: 'Voting District Finder',
        link: 'https://mapping.voteda.org/vdfinder'
      },
      {
        name: 'Geo Profiles',
        link: 'https://geoprofiles.voteda.org/'
      },
      {
        name: 'Structure Map',
        link: 'https://mapping.voteda.org/structures'
      }
    ]
  },
  {
    key: 'governance',
    name: 'Governance Apps',
    apps: [
      {
        name: 'Public Representatives Administration',
        link: 'https://publicreps.voteda.org'
      },
      {
        name: 'Perform',
        link: 'https://perform.voteda.org'
      },
      {
        name: 'Candidate Applications',
        link: 'https://apply.voteda.org'
      }
    ]
  },
  {
    key: 'other',
    name: 'Other Apps',
    apps: [
      {
        name: 'Hunt-Le-Roux Voting System',
        link: 'https://voting.voteda.org'
      },

      {
        name: 'Learn',
        link: 'https://learn.da.org.za/'
      },
      {
        name: 'Validate',
        link: 'https://validate.da-io.net'
      }
    ]
  }
]

export default appCategories
